body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #000; */
  /* color: #fff; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}

.container {
  /* width: 1200px; */
  /* margin: 0 auto; */
  /* text-align: left; */
  /* margin-top: 50px; */
}

input {
  display: block;
  margin: 15px 0;
  width: 400px;
  /* padding: 10px; */
  height: 30px;
}

.blog__content {
  height: 300px;
  display: block;
  border: 1px solid rgb(84, 84, 84);
  overflow: hidden;
}

button {
  margin-top: 20px;
  /* background-color: #000; */
  /* color: #fff; */
  padding: 5px 30px;
  cursor: pointer;
  font-size: 15px;
}
.my_blog {
  /* background-color: #000; */
  padding: 100px 0;
}
.blog_items {
  /* background-color: #000; */
  /* color: #fff; */
  padding: 10px;
  border-top: 2px solid;
  border-bottom: 2px solid;
  /* border-color: #fff; */
  margin: 15px 0;
}
svg {
  /* color: #fff; */
  width: 20px;
  /* margin-left: 20px; */
  font-size: 25px;
  cursor: pointer;
}

.left {
  display: flex;
  justify-content: space-between;
}
.left h3 {
  margin: 0;
}

h1 {
  /* color: #fff; */
}
/* navbar */
.homepage {
  /* background-color: #000; */
  height: 100vh;
}

.homepage span {
  font-size: 23px;
}
.menubar {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 50px 0;
  min-width: 1000px;
  /* background-color: #000; */
}
.menubar a {
  /* color: #fff; */
  text-decoration: none;
  margin-left: 20px;
  font-size: 20px;
  margin-right: 10px;
}

.menubar a:hover {
  text-decoration: underline;
  /* color: #fff; */
}

.menubar svg {
  /* color: #fff; */
}
.home {
  text-align: left;
  /* min-width: 1050px; */
  /* margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* color: #fff; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  /* margin: auto 0; */
  height: 70vh;
  /* margin-left: 50px; */
  margin: 0 30px;
}

.home svg {
  /* color: #fff; */
  margin-left: 0;
}

.home .social a {
  margin-right: 10px;
  font-size: 20px;
  margin-top: 20px;
}
.home .social a svg {
  width: 40px;
}
.blogs {
  min-height: 100vh;
}
.navL {
  justify-content: space-between;
  align-items: center;
}

.blog_item {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 20px auto;
  border-top: 3px solid;
  border-bottom: 3px solid;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 15px 10px;
  align-items: center;
  text-align: left;
  /* border-color: #fff; */
}

.blog_item a {
  /* color: #fff; */
  text-decoration: none;
}

.blog_item .actions {
  display: flex;
  width: 20%;
  justify-content: space-between;
  align-items: center;
}
.blog_item .actions p {
  margin: 0;
}
/* 
.blog .links h4{
  width: 80%;
} */
.blog_item a:hover {
  /* color: #fff; */
  text-decoration: underline;
}
.blog p {
  margin: 0;
}

.single_blog {
  /* background-color: #000; */
  /* color: #fff; */
  min-height: 100vh;
}

.blog_details {
  text-align: left;
  width: 70%;
  margin: 0px auto;
}

.log a {
  margin-left: 5px;
}

.online {
  width: 10px;
  height: 10px;
  background-color: #008ca8;
  display: inline-block;
  border-radius: 50%;
  animation: activeLogin 3.7s linear infinite;
  /* margin: 0 20px; */
  /* opacity: 0; */
}
@keyframes activeLogin {
  0% {
    /* width: 2px;
    height: 2px; */
    opacity: 0;
  }
}

.logout svg {
  color: red;
  font-weight: 800;
  width: 30px;
}
.login svg {
  color: green;
  font-weight: 800;
  width: 30px;
}

.projects,
.about {
  height: 100vh;
}

.about_me {
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
  margin: 0 30px;
}

.about_me h1 {
  text-align: center;
  font-size: 40px;
}

.about_me p {
  font-size: 18px;
}

.login_page {
  /* width: 100vw; */
  min-height: 100vh;
  text-align: center;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* margin-top: 40px; */
}
.log_in {
  width: 500px;
  margin:50px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px solid #222;
  padding: 40px 20px;
  border-radius: 3px;
  background-color: #54525229;
}

.log_in input {
  margin: 15px auto;
  padding: 20px 5px;
  background-color: #ffffff28 !important;
  color: #ffffff;
  border-right: none;
}

.log_in input:focus {
  /* border-color: #fff; */
}

.field {
  display: flex;
  justify-content: center;
  align-items: center;
}

.field svg {
  margin-right: 10px;
  /* color: #fff; */
}

.log_in button {
  margin-top: 20px;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 3px;
}
.log_in p {
  background-color: red;
  padding: 15px;
  border-radius: 5px;
  /* color: #fff; */
  margin-top: 40px;
}

.create_blog {
  display: flex;
  justify-content: space-between;
  width: 80%;
  padding: 30px;
  margin: 0 auto;
  color: rgb(31, 87, 136);
  align-items: center;
}

.create_blog a {
  /* color: #fff; */
  text-decoration: none;
  background-color: #0b945b;
  margin: 0;
  padding: 10px;
  border-radius: 5px;
}

.create_blog a:hover {
  text-decoration: none;
  /* color: #fff; */
}

.ql-toolbar.ql-snow {
  text-align: left;
  margin-top: 0;
  /* color: #fff; */
}

.add_create {
  /* margin: 50px 0; */
  text-align: left;
  /* background-color: #000; */
  /* color: #fff; */
  min-height: 100vh;
}

.add_create input {
  margin: 15px 0;
  padding: 25px 5px;
}

.add_create button {
  /* margin-top: 20px; */
  border-radius: 5px;
  padding: 10px 20px;
  color: #fff;
  border: none;
}
.btn_create {
  background-color: #0b945b;
  border-color: #0b945b;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  /* border-color: #fff; */
  /* color: #fff; */
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  background: #fff;
}

.ql-snow .ql-picker.ql-header {
  width: 98px;
  /* color: #fff; */
}

.btn_update {
  background-color: #137294;
  border-color: #137294;
}

.actions span {
  margin-left: 20px;
}

.content {
  color: #d6d6d6;
  border-top: 2px solid;
  margin-top: 40px;
  padding: 20px 0;
}

.content img {
  display: block;
  width: 70%;
}

.success {
  color: #0b945b;
}

/* modal */

.custom_modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 8, 8, 0.315);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transition: all 5s ease-in-out; */
}

.modal_content {
  background-color: #fff;
  color: #2c2c2c;
  padding: 50px;
  border-radius: 10px;
}

.modal_content p {
  margin: 20px 0;
  font-size: 20px;
}

.modal_content svg {
  font-size: 100px;
  width: 100px;
  font-weight: 100;
}

.modal_btn span {
  padding: 10px 30px;
  /* color: #fff; */
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
}

.cancel_btn {
  background-color: #8e8e8e8c;
}

.delete_btn {
  background-color: #ff0000cb;
  padding: 10px 30px;
  /* color: #fff; */
  text-decoration: none;
  border-radius: 5px;
}

.delete_icon svg {
  color: #ff0000cb;
}
.succes_custom_modal {
  background-color: #0b945b72;
}
.update_modal {
  background-color: #1372948c;
}

.success_modal_cont {
  text-align: center;
  /* background-color: #0b945b; */
}
.success_modal_cont h3 {
  margin-bottom: 20px;
}
.success_modal_cont svg {
  color: #0b945b;
}

.success_modal_btn {
  padding: 10px 30px;
  color: #fff;
  border-radius: 5px;
  background-color: #0b945b;
  text-decoration: none;
}

.success_modal_btn:hover {
  /* color: #fff; */
}

.success_update_btn {
  background-color: #137294;
  color: #fff;
}

.success_update_btn:hover {
  /* color: #fff; */
}
.nothing {
  height: 80vh !important;
}
.update_modal svg {
  color: #137294;
}
/*  */

/* header */

header {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.6s;
  padding: 40px 60px;
  z-index: 1100;
  /* background-color: #000; */
}

header.sticky {
  padding: 5px 100px;
  /* background-color: #000; */
}

header .logo a {
  /* color: #fff; */
  text-decoration: none;
}

header .logo {
  /* position: relative; */
  font-weight: 600;
  /* color: #fff; */
  text-decoration: none;
  font-size: 20px;
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  transition: 0.6s;
}

header ul {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

header ul li {
  position: relative;
  list-style: none;
}

header ul li a {
  position: relative;
  margin: 0 15px;
  text-decoration: none;
  /* color: #fff; */
  letter-spacing: 2px;
  transition: 0.6s;
  font-weight: 500;
}

header .hamburg {
  display: none;
  cursor: pointer;
}

header .hamburg .bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: #fff;
}

@media (max-width: 825px) {
  .hamburg {
    display: block !important;
  }

  .hamburg.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburg.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburg.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  header.sticky .hamburg .bar {
    background-color: #fff;
  }

  header {
    padding: 25px 35px;
  }
  header.sticky {
    padding: 15px 30px;
  }

  header ul {
    position: fixed;
    left: -100%;
    top: 80px;
    flex-direction: column;
    gap: 0;
    background-color: #fff;
    width: 100%;
    text-align: center;
    transition: 0.3s;
    color: #000;
  }

  header.sticky .logo {
    /* color: #fff; */
  }
  header.sticky ul {
    /* background-color: #000; */
  }

  header.sticky ul li a {
    /* color: #fff; */
  }
  header ul li {
    margin: 16px 0;
  }

  header ul.active {
    left: 0;
  }

  .nav_menu.active .nav_link svg {
    background-color: #000 !important;
  }
  .nav_menu.active .nav_link a {
    color: #000 !important;
  }
  .nav_menu.active .nav_link a:hover {
    color: #137294;
  }
}

.Typewriter {
  display: inline-block;
}

.create_blog--btn {
  color: #fff;
}

.create_blog--btn:hover {
  color: #fff;
}
.day {
  padding: 0 10px;
  color: #000;
  margin: 0 5px;
}

.night {
  padding: 0 10px;
  color: #fff;
  margin: 0 5px;
}

.social a {
  color: rgb(0, 86, 161);
  margin: 10px 0;
}

.social a:hover {
  color: rgb(32, 142, 238);
}

.delete__btn {
  color: red;
}

/* ==================== responsiveness ========================== */
/* Small devices (landscape phones, 576px and up) */
@media (max-width: 575px) {
  p {
    font-size: 12px;
  }
  h1 {
    font-size: 18px;
  }
  h2 {
    font-size: 17px;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 15px;
  }
  h5 {
    font-size: 14px;
  }
  h6 {
    font-size: 13px;
  }
  .create_blog {
    width: 100%;
  }
  .blog_item h4,
  .create_blog h4 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .create_blog a {
    font-size: 13px;
  }
  .blog_item .actions {
    width: 40%;
  }
  .actions span {
    margin-left: 14px;
  }

  .blog_item {
    padding: 10px 10px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  p {
    font-size: 14px;
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 16px !important;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 18px;
  }
  .blog_item .actions {
    display: flex;
    width: 35%;
    justify-content: space-between;
    align-items: center;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  p {
    font-size: 16px;
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 24px;
  }
  h5 {
    font-size: 22px;
  }
  h6 {
    font-size: 20px;
  }
}
@media (max-width: 825px) {
  .nav_menu a {
    background-color: #fff !important;
    color: #000 !important;
  }
  .nav_menu svg {
    font-size: 18px;
    color: #000;
  }
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: #fff;
  color: #fff;
}

.ql-snow .ql-picker-label {
  color: #fff;
}
